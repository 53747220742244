import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Features } from '@app/enums';
import { DateInputComponent } from '@app/shared/sb-lib/calendar/date-input/date-input.component';
import { SbFormFieldComponent } from '@app/shared/sb-lib/forms/sb-form-field.component';
import { FeatureService } from '@app/startup/feature.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AbsenceApi } from '@reducers/orm/absence/absence.api';
import { AbsenceStatus, EnhancedAbsenceModel } from '@reducers/orm/absence/absence.model';
import { AbsenceService } from '@reducers/orm/absence/absence.service';
import {
  AnchorButtonComponent,
  BadgeComponent,
  ButtonComponent,
  DialogConfirmComponent,
  DialogConfirmData,
  DialogConfirmResult,
  DialogConfirmType,
  IconAnchorButtonComponent,
  IconButtonComponent,
  IconComponent,
  LoadingStateComponent,
  SbDialogService,
  SbDialogSharedModule,
  StatusList,
  StatuslistComponent,
} from '@sb/ui';
import { format, isAfter, startOfDay, subDays } from 'date-fns';
import { filter, finalize, Observable, take } from 'rxjs';

import { KnowledgeBaseArticleLinkModule } from '../../../pipes/knowledge-base-article-link.module';
import { LocaleDatePipe } from '../../../pipes/locale-date.pipe';
import { TranslationParamsPipe } from '../../../pipes/translation-params.pipe';
import {
  AbsenceSummaryItemComponent,
  EmployeePopoverData,
} from '../../../shared/absence/absence-summary-item/absence-summary-item.component';
import { LaddaDirective } from '../../../shared/ladda/ladda.directive';
import { getTrackEventFn } from './absence-request.helper';

type AbsenceRequestDialogAction = 'approve' | 'edit' | 'deleted' | 'statusChanged' | 'markAsReturned';

export interface AbsenceRequestDialogResult {
  action: AbsenceRequestDialogAction;
  absence: EnhancedAbsenceModel;
}

interface DialogData {
  absence: EnhancedAbsenceModel;
  isOwnAbsence: boolean;
  trackEvent: ReturnType<typeof getTrackEventFn>;
}

@Component({
  selector: 'absence-request',
  standalone: true,
  imports: [
    SbDialogSharedModule,
    KnowledgeBaseArticleLinkModule,
    TranslateModule,
    LaddaDirective,
    LocaleDatePipe,
    TranslationParamsPipe,
    ButtonComponent,
    BadgeComponent,
    IconButtonComponent,
    IconComponent,
    IconAnchorButtonComponent,
    AnchorButtonComponent,
    AbsenceSummaryItemComponent,
    StatuslistComponent,
    ReactiveFormsModule,
    SbFormFieldComponent,
    DateInputComponent,
    LoadingStateComponent,
    AsyncPipe,
  ],
  templateUrl: './absence-request.component.html',
})
export class AbsenceRequestComponent implements OnInit {
  public absence: EnhancedAbsenceModel;
  public isOwnAbsence: boolean;
  public list: StatusList;
  public AbsenceStatus = AbsenceStatus;
  public saving = false;
  public loadingList = false;
  public canMarkAsReturned = false;
  public canApprove: boolean;
  public popoverData$: Observable<EmployeePopoverData>;

  public form = new FormGroup({
    enddate: new FormControl(null, Validators.required),
  });

  private confirmDialog: DialogRef<DialogConfirmResult, DialogConfirmComponent>;
  private trackEvent: ReturnType<typeof getTrackEventFn>;

  public constructor(
    public dialog: SbDialogService,
    public dialogRef: DialogRef<AbsenceRequestDialogResult>,
    @Inject(DIALOG_DATA)
    private readonly data: DialogData,
    private readonly api: AbsenceApi,
    private readonly translate: TranslateService,
    private readonly absenceService: AbsenceService,
    private readonly destroyRef: DestroyRef,
    private readonly featureService: FeatureService,
  ) {}

  public ngOnInit() {
    this.absence = this.data.absence;
    this.isOwnAbsence = this.data.isOwnAbsence;
    this.trackEvent = this.data.trackEvent;

    this.canMarkAsReturned =
      this.featureService.isFeatureActivated(Features.TMP_OPEN_ENDED_ABSENCES) &&
      this.absence.canEdit &&
      this.absence.open_ended &&
      this.absence.status === AbsenceStatus.APPROVED;

    if (this.canMarkAsReturned) {
      const enddateCtrl = this.form.get('enddate');

      const today = startOfDay(new Date());
      const yesterday = subDays(today, 1);
      const startDate = startOfDay(new Date(this.absence.startdate));
      const endDate = startOfDay(new Date(this.absence.enddate));
      if (!isAfter(endDate, today)) {
        const newEndDate = isAfter(startDate, yesterday) ? startDate : yesterday;
        enddateCtrl.setValue(format(newEndDate, 'yyyy-MM-dd'));
      }

      void enddateCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.getAbsenceStatusList(this.absence.id);
      });
    }

    if (this.absence.canApprove || this.canMarkAsReturned) {
      this.getAbsenceStatusList(this.absence.id);
    }

    if (!this.isOwnAbsence) {
      this.popoverData$ = this.absenceService.getPopoverData$(this.absence.Employee.id);
    }
  }

  public changeStatus(status: AbsenceStatus) {
    // from pending to approved
    if (this.absence.status === AbsenceStatus.PENDING && status === AbsenceStatus.APPROVED) {
      this.dialogRef.close({
        action: 'approve',
        absence: this.absence,
      });
      return;
    }

    void this.showConfirmationDialog(this.absence.status, status)
      .pipe(
        filter((result) => result?.confirmed),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        // go to next step when approving
        if (status === AbsenceStatus.APPROVED) {
          this.dialogRef.close({
            action: 'approve',
            absence: this.absence,
          });
        } else {
          this.updateStatus(status);
        }
      });
  }

  public markAsReturned() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const requestData = {
      id: this.absence.id,
      endDate: this.form.get('enddate').value,
    };

    this.updateAbsence(this.absenceService.markAsReturned(requestData), 'markAsReturned');
  }

  private updateStatus(status: AbsenceStatus) {
    const requestData = {
      id: this.absence.id,
      // TODO temporary decision to always notify the employee on status change
      notify_employee: !this.isOwnAbsence && this.absence.canApprove,
      status,
    };

    this.updateAbsence(this.absenceService.save(requestData), 'statusChanged');
  }

  private updateAbsence(request: Observable<any>, action: AbsenceRequestDialogAction) {
    this.saving = true;

    void request
      .pipe(
        finalize(() => (this.saving = false)),
        take(1),
      )
      .subscribe({
        next: () => {
          this.dialogRef.close({
            action,
            absence: this.absence,
          });
        },
        error: (error) => {
          this.trackEvent('Absence Request Modal Failed');
          console.error(error);
        },
      });
  }

  private getAbsenceStatusList(id: string) {
    this.loadingList = true;

    void this.api
      .review(id, this.form.get('enddate').value)
      .pipe(
        finalize(() => (this.loadingList = false)),
        take(1),
      )
      .subscribe({
        next: (checks) => {
          this.list = {
            titleLabels: {
              pass: this.translate.instant('Good'),
              warning: this.translate.instant('Worth checking'),
            },
            items: checks.map((check) => ({
              label: check.message,
              status: check.status,
            })),
          };
        },
        error: (error) => {
          console.error(error);
          this.list = null;
        },
      });
  }

  public editAbsence() {
    this.dialogRef.close({
      action: 'edit',
      absence: this.absence,
    });
  }

  public openDeleteConfirm() {
    this.confirmDialog = this.dialog.openConfirm({
      title: this.translate.instant('Delete absence request?'),
      description: this.translate.instant('Are you sure you want to delete this absence request?'),
      primary: {
        text: this.translate.instant('Delete'),
      },
      secondary: {
        text: this.translate.instant('Cancel'),
      },
      type: DialogConfirmType.danger,
    });

    void this.confirmDialog.closed.pipe(take(1)).subscribe((result) => {
      if (result?.confirmed) {
        this.deleteAbsence();
      }
    });
  }

  private deleteAbsence() {
    this.saving = true;
    void this.absenceService
      .remove(this.absence.id, this.absence.Employee.id, this.absence.AbsenteeDay)
      .pipe(
        take(1),
        finalize(() => (this.saving = false)),
      )
      .subscribe({
        next: () => {
          this.dialogRef.close({
            action: 'deleted',
            absence: this.absence,
          });
        },
        error: (error) => {
          this.trackEvent('Absence Request Modal Failed');
          console.error(error);
        },
      });
  }

  private showConfirmationDialog(
    currentStatus: AbsenceStatus,
    newStatus: AbsenceStatus,
  ): Observable<DialogConfirmResult> {
    const dialogDataMap: Record<AbsenceStatus, DialogConfirmData> = {
      [AbsenceStatus.PENDING]: {
        type: 'info',
        title: this.translate.instant('Update status to Pending?'),
        description: this.translate.instant(
          `Are you sure you want to change the status of this Absence request from {{currentStatus}} back to Pending?`,
          { currentStatus: this.translate.instant(currentStatus) },
        ),
        primary: {
          text: this.translate.instant('Continue'),
        },
        secondary: {
          text: this.translate.instant('Cancel'),
        },
      },
      [AbsenceStatus.APPROVED]: {
        type: 'warning',
        title: this.translate.instant('Approve absence request?'),
        description: this.translate.instant('Are you sure you want to approve this absence request?'),
        primary: {
          text: this.translate.instant('Approve'),
        },
        secondary: {
          text: this.translate.instant('Cancel'),
        },
      },
      [AbsenceStatus.DECLINED]: {
        type: 'warning',
        title: this.translate.instant('Decline absence request?'),
        description: this.translate.instant('Are you sure you want to decline this absence request?'),
        primary: {
          text: this.translate.instant('Decline'),
        },
        secondary: {
          text: this.translate.instant('Cancel'),
        },
      },
    };

    return this.dialog.openConfirm(dialogDataMap[newStatus]).closed;
  }
}
